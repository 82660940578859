<template>
  <v-container>
    <v-card>
      <v-card-title class="justify-center mb-3 titulo">
        <h2>
          Divulgação
        </h2>
      </v-card-title>
      <v-card-text>
        <v-form ref="formDivulgacao">
          <v-row>
            <v-col cols="6">
              <v-select
                  :rules="[campoObrigatorioRule]"
                  v-model="processoSelecionado"
                  return-object
                  :items="processos"
                  clearable
                  item-text="nome"
                  item-value="oid"
                  label="Processos"
                  outlined
              />
            </v-col>
            <v-col cols="6">
              <v-select
                  :rules="[campoObrigatorioRule]"
                  v-model="paramsFiltro.situacoesOid"
                  :items="situacoesInscricao"
                  multiple
                  clearable
                  small-chips
                  item-text="nome"
                  item-value="oid"
                  label="Situação Inscrição"
                  outlined
              />
            </v-col>
          </v-row>
        </v-form>

      </v-card-text>
      <v-card-actions class="justify-end">
        <v-tooltip top>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="primary" @click="filtraHeaders(); dialog = true" v-on="on" v-bind="attrs"
                   elevation="1"
                   icon outlined tile class="mx-1"
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          Adicionar/Remover Colunas
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="primary" :disabled="!!resultados.length"
                   @click="mostrarResultado ? mostrarResultado = false : mostrarResultado = true"
                   v-on="on" v-bind="attrs"
                   elevation="1"
                   icon outlined tile class="mx-1"
            >
              <v-icon>{{ mostrarResultado ? 'mdi-table-eye-off' : 'mdi-table-eye' }}</v-icon>
            </v-btn>
          </template>
          {{ mostrarResultado ? 'Ocultar' : 'Visualizar' }} Colunas
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{on, attrs}">
            <v-btn color="primary" @click="exportarTabela()" :disabled="!resultados.length" v-on="on" v-bind="attrs"
                   elevation="1"
                   icon outlined tile class="mx-1"
            >
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
          </template>
          Exportar
        </v-tooltip>
        <v-btn color="primary" @click="gerarDivulgacao()" class="mx-1"
               elevation="1">
          <v-icon left>mdi-bullhorn</v-icon>
          Gerar
        </v-btn>
      </v-card-actions>
      <v-divider/>
      <v-card-text v-if="mostrarResultado" class="mt-2">
        <v-row justify="center">
          <v-col cols="auto" class="mb-4">
            <h3>{{ titulo }}</h3>
          </v-col>
        </v-row>
        <v-data-table
            :sort-by="['nomeOpcao', 'formacao', 'turno', 'polo']"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [25, 50, 100, 200, 500]
            }"
            :title="titulo"
            :headers="headersDivulgacao"
            :items="resultados"
            :loading="loading"
            class="elevation-2"
            multi-sort
        >
          <template v-slot:footer.prepend>
            <v-spacer/>
            <v-btn
                text
                class="mr-5"
                color="primary"
                @click="exportarTabela()"
                :disabled="!resultados.length"
            >
              <v-icon>mdi-microsoft-excel</v-icon>
              Exportar
            </v-btn>
            <v-btn
                text
                class="mr-5"
                color="pdf"
                @click="exportarPdf"
                :disabled="!resultados"
            >
              <v-icon>mdi-file-pdf-box</v-icon>
              Exportar
            </v-btn>
          </template>
          <template
              v-for="header in headersDivulgacao.filter(h => !h.transient)"
              v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ defaultValue(header, value) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" v-if="dialog" width="65%">
      <v-card>
        <v-card-title class="primary justify-center white--text">
          Alterar Colunas
        </v-card-title>
        <v-card-text>
          <side-by-side-multi-select
              class="mt-2"
              :items="headersDisponiveis"
              v-model="headersTemporarios"
              :ordena-escolhidos="true"
              :ordenacao-automatica="false"
              :reinicia-estado="false"
          />
        </v-card-text>
        <v-divider/>
        <v-card-actions class="justify-space-around">
          <v-btn color="error" @click="dialog = false">
            Fechar
          </v-btn>
          <v-btn color="primary" @click="alterarHeader">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import processoService from "@/services/processo.service";
import situacaoInscricaoService from "@/services/situacaoInscricao.service";
import divulgacaoService from "@/services/divulgacao.service";
import _ from "lodash";
import XLSX from "xlsx";
import {mapActions} from "vuex";
import rules from "@/commons/rules";
import SideBySideMultiSelect from "@/components/base/SideBySideMultiSelect";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import luxon from "@/core/luxon";

export default {
  name: "Divulgacao",
  components: {SideBySideMultiSelect},

  data() {
    return {
      dialog: false,
      titulo: '',
      processoSelecionado: null,
      processos: [],
      situacoesInscricao: [],
      paramsFiltro: {
        situacoesOid: []
      },
      mostrarResultado: false,
      headersDivulgacao: [],
      headersTemporarios: [],
      headersDisponiveis: [],
      headers: [
        {text: "Opção", value: "nomeOpcao", titulo: "Opção"},
        {text: "Formação", value: "formacao", titulo: "Formação"},
        {text: "Turno", value: "turno", titulo: "Turno"},
        {text: "Polo", value: "polo", titulo: "Polo"},
        {text: "Nome", value: "nome", titulo: "Nome"},
        {text: "Número de Inscrição", value: "numeroInscricao", titulo: "Número de Inscrição"},
        {text: "Situação Inscrição", value: "situacao", titulo: "Situação Inscrição"},
        {text: "Número ENEM", value: "numeroEnem", titulo: 'Número ENEM'},
        {text: "Posicao", value: "posicao", titulo: "Posicao"},
        {text: "Classificação", value: "classificacao", titulo: "Classificação"},
        {text: "Nota ENEM", value: "notaEnem", titulo: "Nota ENEM"},
        {text: "Modalidade", value: "fila", titulo: "Modalidade"},
        {text: "Prova Específica", value: "notaEspecifica", titulo: "Prova Específica"},
        {text: "Prova Redação", value: "notaRedacao", titulo: "Prova Redação"},
        {text: "Prova Teórica", value: "notaTeorica", titulo: "Prova Teórica"},
        {text: "Prova Prática", value: "notaPratica", titulo: "Prova Prática"},
        {text: "Nota Final", value: "notaFinal", titulo: "Nota Final"},
        {text: "Resultado", value: "resultado", titulo: "Resultado"},
        {text: "Curso Origem", value: "cursoOrigem", titulo: "Curso Origem"},
        {text: "CRA", value: "cra", titulo: "CRA"},
        {text: "Data Extração do CRA", value: "dataExtracao", titulo: "Data Extração do CRA"},
        {text: "Percentual de Bônus Aplicado", value: "bonus", titulo: "Percentual de Bônus Aplicado"}
      ],
      headersBase: [
        {text: "Opção", value: "nomeOpcao", titulo: "Opção"},
        {text: "Formação", value: "formacao", titulo: "Formação"},
        {text: "Turno", value: "turno", titulo: "Turno"},
        {text: "Polo", value: "polo", titulo: "Polo"},
        {text: "Nome", value: "nome", titulo: "Nome"}
      ],
      resultados: [],
      loading: false,
      nomeExportacaoPDF: "Relatorio"
    }
  },

  async created() {
    let loader = this.$loading.show();
    await processoService
        .recuperarProcessosConsulta()
        .then(response => {
          this.processos = response.data;
        })
        .catch(() => {
        });

    await situacaoInscricaoService
        .recuperarSituacoesInscricao()
        .then(response => {
          this.situacoesInscricao = response.data;
        });
    this.headersDivulgacao = _.cloneDeep(this.headersBase)
    this.headersTemporarios = _.cloneDeep(this.headersBase)
    loader.hide();
  },

  methods: {
    ...rules,
    ...mapActions("avisos", ["exibirAviso"]),

    alterarHeader() {
      this.dialog = false;
      this.headersDivulgacao = _.cloneDeep(this.headersTemporarios);
    },

    filtraHeaders() {
      this.headersTemporarios = _.cloneDeep(this.headersDivulgacao);
      let values = this.headersDivulgacao.map(h => h.value);
      this.headersDisponiveis = this.headers.filter(h => !values.includes(h.value));
    },

    tratarDataNumerica(data) {
      if (data === undefined) {
        return undefined
      }
      return luxon.dataConverter(data).toLocaleString();
    },

    defaultValue(header, value) {
      if (typeof value !== "number" && !value) {
        header.align = "center";
        return "-";
      }

      if (luxon.dataValida(value) && header.value.includes('data')) {
        return this.tratarDataNumerica(value);
      }

      return value;
    },

    async gerarDivulgacao() {
      if (this.$refs.formDivulgacao.validate()) {
        let loader = this.$loading.show();

        this.loading = true;
        this.resultados = [];

        await divulgacaoService.gerarDivulgacao(
            this.paramsFiltro
        )
            .then(response => {
              this.resultados = response.data;
              this.mostrarResultado = true;
            })
            .catch(() => {
              this.exibirAviso({
                mensagem: "Não foi possível gerar a divulgação",
                tipo: "error"
              });
              this.loading = false;
              loader.hide();
            });

        this.loading = false;
        loader.hide();
      }
    },

    async exportarTabela() {
      let loader = this.$loading.show();

      let resultado = this.resultados;

      if (resultado && resultado.length) {
        let header = this.headersDivulgacao.map(h => h.value);
        let dataRows = [];
        resultado.map(row => {
          let dataRow = [];

          header.map(column => {
            dataRow.push(row[column]);
          });
          dataRows.push(dataRow);
        });

        let nomes = this.headersDivulgacao.map(h => h.text);

        const ws = XLSX.utils.aoa_to_sheet([nomes, ...dataRows]);
        const wb = XLSX.utils.book_new();

        let name = this.titulo;

        if (name.length >= 30) {
          let inicio = name.substring(0, 11);
          let fim = name.substring(name.length - 11, name.length);

          name = inicio + "..." + fim;
        }

        XLSX.utils.book_append_sheet(wb, ws, name);
        XLSX.writeFile(wb, this.titulo + ".xlsx");
      } else {
        this.exibirAviso({
          mensagem: "Não há dados para divulgar, tente pré-visualizar o resultado primeiro",
          tipo: "info"
        });
      }

      loader.hide();
    },

    async exportarPdf() {
      let loader = this.$loading.show();

      let resultado;

      await divulgacaoService.gerarDivulgacao(
          this.paramsFiltro
      )
          .then(response => {
            resultado = response.data;
          })
          .catch(() => {
            loader.hide();
          });

      if (resultado) {
        let header = this.headersDivulgacao.map(h => h.value);
        let dataRows = [];
        resultado.map(row => {
          let dataRow = [];

          header.map(column => {
            dataRow.push(row[column]);
          });
          dataRows.push(dataRow);
        });

        let nomes = this.headersDivulgacao.map(h => h.text);
        let doc = new jsPDF('l', 'pt', 'a3');

        autoTable(doc, {
          head: [nomes], body: dataRows,
          headStyles: {
            fillColor: [137, 199, 158],
          }
        });
        doc.save(this.titulo + " - " + this.nomeExportacaoPDF + ".pdf");
      }
    }
  },

  watch: {
    async processoSelecionado(valor) {
      if (valor) {
        if (this.paramsFiltro.processoOid && this.paramsFiltro.processoOid !== valor.oid) {
          if (this.mostrarResultado && this.$refs.formDivulgacao.validate() && !!this.resultados.length) {
            await this.gerarDivulgacao();
          } else {
            this.resultados = [];
            this.mostrarResultado = false;
          }
        }

        this.titulo = valor.nome
        this.paramsFiltro.processoOid = valor.oid
      }
    },

    'paramsFiltro.situacoesOid': {
      async handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          let loader = this.$loading.show();

          if (this.mostrarResultado && this.$refs.formDivulgacao.validate() && !!this.resultados.length) {
            await this.gerarDivulgacao();
          } else {
            this.resultados = [];
            this.mostrarResultado = false;
          }

          loader.hide();
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>