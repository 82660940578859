var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',{staticClass:"justify-center mb-3 titulo"},[_c('h2',[_vm._v(" Divulgação ")])]),_c('v-card-text',[_c('v-form',{ref:"formDivulgacao"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"rules":[_vm.campoObrigatorioRule],"return-object":"","items":_vm.processos,"clearable":"","item-text":"nome","item-value":"oid","label":"Processos","outlined":""},model:{value:(_vm.processoSelecionado),callback:function ($$v) {_vm.processoSelecionado=$$v},expression:"processoSelecionado"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"rules":[_vm.campoObrigatorioRule],"items":_vm.situacoesInscricao,"multiple":"","clearable":"","small-chips":"","item-text":"nome","item-value":"oid","label":"Situação Inscrição","outlined":""},model:{value:(_vm.paramsFiltro.situacoesOid),callback:function ($$v) {_vm.$set(_vm.paramsFiltro, "situacoesOid", $$v)},expression:"paramsFiltro.situacoesOid"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"primary","elevation":"1","icon":"","outlined":"","tile":""},on:{"click":function($event){_vm.filtraHeaders(); _vm.dialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}])},[_vm._v(" Adicionar/Remover Colunas ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"primary","disabled":!!_vm.resultados.length,"elevation":"1","icon":"","outlined":"","tile":""},on:{"click":function($event){_vm.mostrarResultado ? _vm.mostrarResultado = false : _vm.mostrarResultado = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mostrarResultado ? 'mdi-table-eye-off' : 'mdi-table-eye'))])],1)]}}])},[_vm._v(" "+_vm._s(_vm.mostrarResultado ? 'Ocultar' : 'Visualizar')+" Colunas ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"primary","disabled":!_vm.resultados.length,"elevation":"1","icon":"","outlined":"","tile":""},on:{"click":function($event){return _vm.exportarTabela()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)]}}])},[_vm._v(" Exportar ")]),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","elevation":"1"},on:{"click":function($event){return _vm.gerarDivulgacao()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-bullhorn")]),_vm._v(" Gerar ")],1)],1),_c('v-divider'),(_vm.mostrarResultado)?_c('v-card-text',{staticClass:"mt-2"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"mb-4",attrs:{"cols":"auto"}},[_c('h3',[_vm._v(_vm._s(_vm.titulo))])])],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"sort-by":['nomeOpcao', 'formacao', 'turno', 'polo'],"footer-props":{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [25, 50, 100, 200, 500]
          },"title":_vm.titulo,"headers":_vm.headersDivulgacao,"items":_vm.resultados,"loading":_vm.loading,"multi-sort":""},scopedSlots:_vm._u([{key:"footer.prepend",fn:function(){return [_c('v-spacer'),_c('v-btn',{staticClass:"mr-5",attrs:{"text":"","color":"primary","disabled":!_vm.resultados.length},on:{"click":function($event){return _vm.exportarTabela()}}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")]),_vm._v(" Exportar ")],1),_c('v-btn',{staticClass:"mr-5",attrs:{"text":"","color":"pdf","disabled":!_vm.resultados},on:{"click":_vm.exportarPdf}},[_c('v-icon',[_vm._v("mdi-file-pdf-box")]),_vm._v(" Exportar ")],1)]},proxy:true},_vm._l((_vm.headersDivulgacao.filter(function (h) { return !h.transient; })),function(header){return {key:("item." + (header.value)),fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.defaultValue(header, value))+" ")]}}})],null,true)})],1):_vm._e()],1),(_vm.dialog)?_c('v-dialog',{attrs:{"width":"65%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary justify-center white--text"},[_vm._v(" Alterar Colunas ")]),_c('v-card-text',[_c('side-by-side-multi-select',{staticClass:"mt-2",attrs:{"items":_vm.headersDisponiveis,"ordena-escolhidos":true,"ordenacao-automatica":false,"reinicia-estado":false},model:{value:(_vm.headersTemporarios),callback:function ($$v) {_vm.headersTemporarios=$$v},expression:"headersTemporarios"}})],1),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-space-around"},[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.alterarHeader}},[_vm._v(" Confirmar ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }